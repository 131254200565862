import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const PageContext = createContext({})

export const PageContextProvider = ({ value, children }) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

PageContextProvider.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
}

export const usePageContext = () => useContext(PageContext)
